<template>
  <div class="home">
    <div class='imgbox' v-if="isShow == true || id==''">
      <div class='img2'>
        <img src="../assets/bull.png" alt="">
      </div>
    </div>
    <div v-else class='Views_div'>
      <vue-office-pdf v-if="link_url.endsWith('.pdf')" :src="link_url" height='100%' @rendered="renderedHandler"
        @error="errorHandler" />
      <vue-office-docx v-else-if="link_url.endsWith('.docx')" :src="link_url" style="height: 100vh; width: 100%;" @rendered="rendered" />
      <!-- <div v-else-if="isDocx(link_url)" height='100%'>{{ link_url }}</div> -->
      <iframe  v-else :src="link_url"  frameborder="0" height='100%' width='100%'></iframe>
      <!-- <iframe src="https://view.officeapps.live.com/op/view.aspx?src=https://vross.zhyell.com/qrcode/yidazhiershida.pdf"  frameborder="0"></iframe> -->
    </div>
  </div>
</template>
<script>
  //引入VueOfficePdf组件
  import VueOfficePdf from '@vue-office/pdf'
  //引入VueOfficeDocx组件
  import VueOfficeDocx from '@vue-office/docx'
  //引入相关样式
  import '@vue-office/docx/lib/index.css'
  export default {
    components: {
      VueOfficePdf,
      VueOfficeDocx
    },
    name: 'HomeView',
    data() {
      return {
        id: '',
        link_url: '',
        numPages: null,
        isShow: false,
        // pdfSrc: loadingTask
      }
    },

    mounted() {
      if (this.$route.query.id) {
        this.id = this.$route.query.id
        this.getQrcode()

      }


    },
    methods: {
      renderedHandler() {
        console.log("渲染完成")
      },
      errorHandler() {
        console.log("渲染失败")
      },
      rendered(){},
      getQrcode() {
        let _this = this
        this.$axios.post('https://develop.himeit.com/api/admin/get_qrcode_info', {
          id: _this.id
        }).then(function(res) {
          if (res.data.code == 0 || res.data.data.status == 1) {
            _this.link_url = res.data.data.url
            _this.logNumCode()
            if(res.data.data.is_dump|| res.data.data.url.endsWith('.jpg') ){
                window.location.href = res.data.data.url
            }
          } else if (res.data == 1) {
            _this.isShow = true
          }
        }).catch(function(err) {
          console.log(err, 'err')
          // alert('没有识别到当前url')
        });
      },
      logNumCode() {
        this.$axios.post('https://develop.himeit.com/api/qrcode_record', {
          url_id: this.id
        }).then(res => {
          console.log( '成功')
        })
      },

    }
  }
  // }
</script>
<style>
  body{
    overflow-y: none;
  }
  .imgbox {
    width: 100%;
    height: 100vh;
    position: relative;
    /* background-color: rgba(0, 0, 0, .3); */
  }

  .img2 {
    margin-top: 20px;
    width: 100%;
    height: 100%;
  }

  .img2 img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }

  .Views_div {
    height: 100vh;
    overflow-y: none !important;
  }

  .Views_div .vue-office-pdf {
    height: 100% !important;
  }

  .Views_div iframe {
    height: 100%;
    width: 100%;
    overflow-y: none;
  }
  .Views_div iframe img {

     height: 100%;
     width: 100%;
     object-fit: fill;
  }
  .pdf-container {
    width: 100%;
    height: 800px;
  }
</style>
